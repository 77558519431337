import { useEffect, useState } from "react";
import { useOrientation, useWindowSize } from "react-use";

export const useDisplay = () => {
  const [deviceType, setDeviceType] = useState({
    isStandalone: true,
    isMobile: null,
    isMobileInPortrait: null,
    isMobileInLandscape: null,
    isTablet: null,
    isTabletInPortrait: null,
    isTabletInLandscape: null,
    isLaptop: null,
    orientation: "portrait", // or 'landscape'
    width: 0,
    height: 0,
  });

  const { type } = useOrientation();
  const windowSize = useWindowSize();

  useEffect(() => {
    const { width, height } = windowSize;
    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches;

    const orientation = type.split("-")[0];
    const measure = Math.min(width, height);
    // Determine device type and orientation with adjusted breakpoints
    const isMobile = isTouchDevice && measure <= 768;
    const isTablet = isTouchDevice && measure <= 1024 && measure > 768;
    const isLaptop = !isTouchDevice || measure >= 1025;
    const isMobileInPortrait = isMobile && orientation === "portrait";
    const isMobileInLandscape = isMobile && orientation === "landscape";
    const isTabletInPortrait = isTablet && orientation === "portrait";
    const isTabletInLandscape = isTablet && orientation === "landscape";

    setDeviceType({
      isStandalone,
      isMobile,
      isMobileInPortrait,
      isMobileInLandscape,
      isTablet,
      isTabletInPortrait,
      isTabletInLandscape,
      isLaptop,
      orientation,
      width,
      height,
    });
  }, [type, windowSize]);

  return deviceType;
};
