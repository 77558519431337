import { Each } from "src/shiftly-ui";

import styles from "./UpcomingShifts.module.css";

import ShiftCard from "./ShiftCard";

const UpcomingShifts = ({ shifts = [], setShowDetails = () => {} }) => {
  return (
    shifts.length > 0 && (
      <div className={styles["container"]}>
        <div className={styles["heading"]}>
          <h3>Confirmed Shifts</h3>
          <div className={styles["badge"]}>{shifts.length}</div>
        </div>
        <Each
          of={shifts}
          render={({ key, ...shift }) => <ShiftCard key={key} shift={shift} setShowDetails={setShowDetails} />}
        />
      </div>
    )
  );
};

export default UpcomingShifts;
