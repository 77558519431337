import { useInlineStyles, useToast } from "src/shiftly-ui";

import React, { useState, useEffect, forwardRef } from "react";

import { Autocomplete, Chip, TextField } from "@mui/material";

import useValidation from "src/pages/search/components/search_bar/shift_filter/Filters/hooks/useValidation";

import styles from "./InputStyles.module.css";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

const MultiSelect = forwardRef(
  (
    {
      options = [],
      label = "",
      placeholder = "",
      message = "",
      disabled,
      inActive = false,
      className,
      multi = true,
      disabledClickError = "",
      withCreate = false,
      createCallback = () => {},
      disabledFunc = () => {},
      inputProps = {},
      ...props
    },
    ref
  ) => {
    const { error, setTouched, value, onChange } = useValidation(props);

    const style = useInlineStyles({ ...props });

    const [selectedOptions, setSelectedOptions] = useState(value || []);

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [updatedOptions, setUpdatedOptions] = useState(options);

    const [inputValue, setInputValue] = useState("");

    const toast = useToast();

    useEffect(() => {
      setSelectedOptions();

      return () => {
        setDropdownOpen(false);
      };
    }, []);

    useEffect(() => {
      if (multi) {
        setSelectedOptions(value);
      } else {
        const selVal = options.find((option) => option.value === value);

        if (!selVal && !value?.value) return;

        setInputValue(selVal?.label || value?.label);

        setSelectedOptions(selVal || value);
      }
    }, [value, options, multi]);

    useEffect(() => {
      if (!withCreate) return;

      const opt = updatedOptions.find((option) => option.value === value) || "";

      opt && setSelectedOptions(opt);
      // eslint-disable-next-line
    }, [value]);

    const updateCreateOption = (value, clear = false) => {
      setUpdatedOptions((prev) => {
        const newArr = structuredClone(prev);

        const createOptionIndex = newArr.findIndex((option) => option.createOption);

        if (clear) {
          return newArr.filter((option) => !option.createOption);
        }

        if (createOptionIndex > -1) {
          newArr[createOptionIndex] = {
            label: `Create "${value || ""}"`,
            inputValue: value,
            createOption: true,
          };

          return [...newArr];
        }

        return [
          ...newArr,
          {
            label: `Create "${value || ""}"`,
            inputValue: value,
            createOption: true,
          },
        ];
      });
    };

    useEffect(() => {
      if (withCreate && inputValue && !options.find((option) => option.value === inputValue)) {
        updateCreateOption(inputValue);
      } else {
        setUpdatedOptions(options);
      }
    }, [options, inputValue, withCreate]);

    // Handle selection change
    const handleSelectionChange = (newValue) => {
      setTouched(true);

      if (newValue && newValue.createOption) {
        // Call the createCallback if the selected option is the create option
        createCallback(newValue.inputValue, addOption);

        setInputValue("");

        updateCreateOption("", true);
      } else {
        onChange(newValue);

        setSelectedOptions(newValue);
      }
    };

    // Add a new option to the options list
    const addOption = (newOption) => {
      onChange(newOption); // Update the value with the newly created option

      setInputValue(newOption.label);

      setSelectedOptions(newOption);
    };

    return (
      <div
        className={clsx(styles["input-wrapper"], className)}
        style={style}
        onClick={() => {
          if (disabled) toast.error(disabledClickError, "Input Disabled");
        }}
      >
        {label && <label className={clsx(styles["label"], "small")}>{label}</label>}
        <div className={styles["select-wrapper"]}>
          <Autocomplete
            open={isDropdownOpen}
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
            isOptionEqualToValue={(option, value) => {
              return option?.value === value?.value;
            }}
            getOptionLabel={(option) => (typeof option === "string" ? option : option?.label || "")}
            getOptionDisabled={disabledFunc}
            multiple={multi}
            options={updatedOptions}
            value={selectedOptions || (multi ? [] : "")}
            onChange={(_, newValue) => {
              handleSelectionChange(newValue);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "0.5px solid var(--secondary-400, #94a3b9) !important",
                  borderRadius: "7px !important",
                },
              },
            }}
            className={styles["multi-select-component"]}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...chipProps } = getTagProps({ index });

                return (
                  <Chip
                    key={key}
                    label={typeof option === "string" ? option : option.label}
                    {...chipProps}
                    sx={{
                      bgcolor: "#fdece3",
                      color: "#d14031",
                      borderRadius: "4px",
                    }}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={placeholder}
                className={clsx(styles["input"])}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={clsx(styles["chevron"], isDropdownOpen && styles["chev-open"])}
                      />
                    </React.Fragment>
                  ),
                }}
              />
            )}
            disabled={disabled || inActive}
            {...inputProps}
            ref={ref}
          />
        </div>
        {error && (
          <div className={styles["error-container"]}>
            <p className={clsx(styles["message"], "small")}>{message}</p>
          </div>
        )}
      </div>
    );
  }
);

export default MultiSelect;
