import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./NotificationWithLink.module.css";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons";
import placeholderImage from "../../assets/png/no_img_placeholder.jpeg";
import { Link } from "../buttons/Link";
import { useNavigate } from "react-router-dom";
import { truncate } from "../../utility/format.js";

export const NotificationWithLink = ({ title, icon, link = {}, text }) => {
  const navigate = useNavigate();
  return (
    <div className={styles["container"]} onClick={() => navigate(link?.url)}>
      <div className={styles["image-container"]}>
        <img src={icon || placeholderImage} alt="" className={styles["image"]} />
        <div className={styles["checks"]}>
          <FontAwesomeIcon icon={faCheckDouble} />
        </div>
      </div>
      <div className={styles["content"]}>
        <div className={styles["body"]}>
          <p>
            <strong>{title}: </strong>
            {truncate(text, 50)}
          </p>
        </div>
        <div className={styles["link"]}>
          <Link to={link.url}>{link.text}</Link>
        </div>
      </div>
    </div>
  );
};
