import { MapElement } from "src/shiftly-ui";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import styles from "./MapView.module.css";

import useShifts from "src/hooks/useShifts";

import ShiftDetails from "./shift_details/ShiftDetails";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

const getCachedFilter = () => {
  const cachedFilter = localStorage.getItem("shiftFilter");

  if (cachedFilter) {
    return JSON.parse(cachedFilter);
  }

  return {
    what: {
      ids: [],
      descriptions: [],
    },
    when: [],
    wage: 0,
  };
};

const MapView = () => {
  const [camera, setCamera] = useState({
    center: {
      lat: -33.8688,
      lng: 151.2093,
    },
    zoom: 10,
  });

  const [showDetails, setShowDetails] = useState(false);

  const { filteredShifts, shiftIsLoading, handleSearch: handleShiftSearch, setFilter } = useShifts();

  const cameraRef = useRef(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      localStorage.setItem(
        "location",
        JSON.stringify({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        })
      );

      setCamera({
        center: {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        },
        zoom: 10,
      });
    });
  }, []);

  useEffect(() => {
    //Clean Up When Leaving
    return () => {
      const { what, when, wage } = getCachedFilter();

      setFilter({
        what,
        when,
        wage,
        where: {},
      });

      handleShiftSearch({
        what,
        when,
        wage,
        where: {},
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleSearch();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [camera]);

  const handleSearch = useCallback(() => {
    const distance = cameraRef.current?.getDistanceFromCenter();

    const center = camera?.center;

    if (!distance || !center) return;

    handleShiftSearch({
      ...getCachedFilter(),
      where: {
        fullAddress: "Map View",
        longitude: center.lng,
        latitude: center.lat,
        range: distance,
      },
    });
  }, [cameraRef, handleShiftSearch, camera]);

  const pins = useMemo(
    () =>
      filteredShifts.flatMap((shift) => {
        const coordinates = shift?.address?.pinpoint?.coordinates;

        if (!coordinates) return [];

        return {
          position: {
            lng: coordinates[0],
            lat: coordinates[1],
          },
          shift,
          callback: (pin) => {
            setShowDetails(pin.shift);
          },
        };
      }),
    [filteredShifts]
  );

  return (
    <>
      <ShiftDetails showDetails={showDetails} setShowDetails={setShowDetails} />
      <div className={styles["map-view-container"]}>
        <div className={styles["search-this"]} onClick={handleSearch}>
          {shiftIsLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <p>Search this area</p>}
        </div>
        <MapElement ref={cameraRef} camera={camera} setCamera={setCamera} pins={pins} />
      </div>
    </>
  );
};

export default MapView;
