import { useStyling, formatPayPeriod, Button, useFetch, useToast, useAuth } from "src/shiftly-ui";

import { useCallback, useEffect, useState } from "react";

import styles from "./PayRunNotification.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";

import moment from "moment";

import { motion } from "framer-motion";

const formatPayDate = (date) => {
  return moment(date).format("Do MMMM");
};

const PayRunNotification = ({ payrun = {} }) => {
  const [show, setShow] = useState(false);

  const styling = useStyling(styles);

  const { user } = useAuth();

  const { pay_date, start_date, end_date } = payrun;

  const toast = useToast();

  const { post: sendPaySlip } = useFetch({
    options: {
      onMutate: () => {
        handleCloseNotification();

        toast.success("Pay slip sent. Please check your emails.");
      },
    },
  });

  const handleSendPayslip = useCallback(() => {
    sendPaySlip({
      entity: "PaySlip",
      method: "sendPayslips",
      criteria: {
        payrun: payrun._id,
        user: user._id,
        $or: [{ sent: false }, { sent: true }],
      },
    });
  }, [payrun, user, sendPaySlip]);

  const handleCloseNotification = useCallback(() => {
    setShow(false);

    localStorage.setItem("lastNotifiedPayrun", payrun._id);
  }, [payrun._id]);

  useEffect(() => {
    if (!payrun._id) return;

    const lastNotifiedId = localStorage.getItem("lastNotifiedPayrun");

    if (lastNotifiedId !== payrun._id) {
      setShow(true);

      localStorage.setItem("lastNotifiedPayrun", payrun._id);
    }
  }, [payrun._id]);

  return (
    <motion.div
      initial={{ y: "110%" }}
      animate={{ y: show ? "0" : "110%" }}
      transition={{ duration: 0.2 }}
      className={styling("container")}
    >
      <div className={styling("wrapper")}>
        <FontAwesomeIcon icon={faTimes} className={styling("close-icon")} onClick={handleCloseNotification} />
        <div className={styling("status")}>
          <FontAwesomeIcon icon={faCheck} />
          <p>Paid {formatPayDate(pay_date)}</p>
        </div>
        <div className={styling("title")}>
          <h4>Payrun: {formatPayPeriod(start_date, end_date)}</h4>
        </div>
        <div className={styling("content")}>
          <p>
            We have emailed your payslip to you.
            <br />
            Didn't receive it?
          </p>
          <Button theme={"secondary-outline"} className={styling("download-btn")} onClick={handleSendPayslip}>
            Send Payslip
          </Button>
          <p>
            <span>Payments may take 1 - 2 business days to show in your account.</span>
          </p>
          <p className={styling("subtext")}>All historical pay runs can be found in payment history.</p>
        </div>
      </div>
    </motion.div>
  );
};

export default PayRunNotification;
