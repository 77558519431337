import { Button, useStyling, useFetch, Overlay } from "src/shiftly-ui";

import { useEffect, useRef } from "react";

import styles from "./RatingPopout.module.css";

import { useCallback, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faStar } from "@fortawesome/pro-solid-svg-icons";

import { faStar as outlineStar } from "@fortawesome/pro-light-svg-icons";

import { motion } from "framer-motion";

import { useUncontrolled } from "uncontrollable";

const RatingPopout = (props) => {
  const {
    show = false,
    setShow = () => {},
    shift = {},
  } = useUncontrolled(props, {
    show: "setShow",
  });

  const [rating, setRating] = useState(0);

  const [hoverRating, setHoverRating] = useState(0);

  const starContainerRef = useRef(null);

  const styling = useStyling(styles);

  const { post: sendRating } = useFetch({
    options: {
      onMutate: () => {
        setShow(false);
      },
    },
  });

  useFetch({
    request: {
      entity: "Rating",
      method: "get",
      criteria: { shift: shift?._id, location: shift?.location?._id },
    },
    options: {
      onSuccess: (data) => {
        if (!data.length) setShow(true);
      },
    },
  });

  const handleSaveRating = useCallback(() => {
    sendRating({
      entity: "Rating",
      method: "create",
      data: { rating, shift: shift._id, location: shift.location._id },
    });
  }, [sendRating, rating, shift]);

  const handleTouchMove = (e) => {
    const touch = e.touches[0];

    const stars = Array.from(starContainerRef.current.children);

    stars.forEach((star, index) => {
      const rect = star.getBoundingClientRect();

      if (touch.clientX >= rect.left && touch.clientX <= rect.right) {
        setHoverRating(index + 1);
      }
    });
  };

  const handleMouseMove = (e) => {
    const stars = Array.from(starContainerRef.current.children);

    stars.forEach((star, index) => {
      const rect = star.getBoundingClientRect();

      if (e.clientX >= rect.left && e.clientX <= rect.right) {
        setHoverRating(index + 1);
      }
    });
  };

  useEffect(() => {
    const handleTouchEnd = () => setRating(hoverRating);

    const handleMouseLeave = () => setHoverRating(rating);

    const starContainer = starContainerRef.current;

    starContainer.addEventListener("touchmove", handleTouchMove);

    starContainer.addEventListener("mousemove", handleMouseMove);

    starContainer.addEventListener("mouseleave", handleMouseLeave);

    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      starContainer.removeEventListener("touchmove", handleTouchMove);

      starContainer.removeEventListener("mousemove", handleMouseMove);

      starContainer.removeEventListener("mouseleave", handleMouseLeave);

      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [hoverRating, rating]);

  return (
    <>
      <Overlay open={show} />
      <motion.div
        initial={{ y: "120%" }}
        animate={{ y: show ? "0%" : "120%" }}
        transition={{ duration: 0.3 }}
        className={styling("container")}
      >
        <div className={styling("wrapper")}>
          <div className={styling("location-logo")}>
            <img src={shift?.location?.logo} alt="Locations Logo" />
          </div>
          <div className={styling("heading")}>
            <h3>How was your shift at {shift?.location?.name}?</h3>
            <p className={styling("rating")}>
              <span>{shift?.location?.rating}</span>
              <FontAwesomeIcon icon={faStar} />
            </p>
          </div>
          <div className={styling("stars")} ref={starContainerRef}>
            {Array.from({ length: 5 }, (_, i) => (
              <div
                key={i}
                className={styling(`star${i + 1 <= (hoverRating || rating) ? "-active" : ""}`)}
                onClick={() => setRating(i + 1)}
              >
                <FontAwesomeIcon icon={i + 1 <= (hoverRating || rating) ? faStar : outlineStar} />
              </div>
            ))}
          </div>
          <div className={styling("button-container")}>
            <Button theme={"primary"} onClick={handleSaveRating}>
              Send Rating
            </Button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default RatingPopout;
