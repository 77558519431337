import { FormContextProvider } from "../../contexts/FormV2";
import clsx from "clsx";
import { useUncontrolled } from "uncontrollable";
import { forwardRef, useRef, useEffect, useImperativeHandle, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "../../hooks/useToast";
import debounce from "lodash.debounce";

export const FormV2 = forwardRef(function FormV2(props, ref) {
  const {
    children,
    onSubmit = () => {},
    onChange,
    className,
    initial,
    data,
    setData = () => {},
    resetOnSubmit = false,
    toastOnError = false,
    errorMessage = "Something doesn't look right. Please check the form for errors.",
    ...otherProps
  } = useUncontrolled(props, { data: "setData" });

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    setFocus,
    setError,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => initial || data, [initial, data]),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const toast = useToast();
  const formRef = useRef(null);
  const previousValuesRef = useRef(data);

  const syncFormData = useCallback(
    (data) => {
      if (!data) return;
      const currentFormValues = getValues();
      const dataKeys = Object.keys(data || {});

      dataKeys.forEach((key) => {
        if (currentFormValues[key] !== data[key]) {
          setValue(key, data[key], { shouldValidate: false, shouldDirty: false });
        }
      });

      const allKeys = Object.keys(currentFormValues);
      allKeys.forEach((key) => {
        if (!dataKeys.includes(key)) {
          setValue(key, "", { shouldValidate: false, shouldDirty: false });
        }
      });
    },
    [setValue, getValues]
  );

  useEffect(() => {
    syncFormData(initial);
  }, [initial, syncFormData]);

  //eslint-disable-next-line
  const syncData = useCallback(
    debounce((currentValues) => {
      if (JSON.stringify(currentValues) !== JSON.stringify(previousValuesRef.current)) {
        setData(currentValues);
        onChange?.(currentValues);
        previousValuesRef.current = currentValues;
      }
    }, 300),
    [setData, onChange]
  );

  const formValues = watch();

  useEffect(() => {
    syncData(formValues);
  }, [formValues, syncData]);

  useEffect(() => {
    syncFormData(data);
  }, [data, syncFormData]);

  const scrollToElement = useCallback((key) => {
    const element = document.querySelector(`[name=${key}]`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    return element;
  }, []);

  const onError = useCallback(
    (errorObj) => {
      for (const key in errorObj) {
        const element = scrollToElement(key);
        if (element) {
          break;
        }
      }

      toastOnError && toast.error(errorMessage);
    },
    [toast, errorMessage, toastOnError]
  );

  const onSubmitHandler = useCallback(
    async (data) => {
      try {
        const response = await onSubmit(data);
        if (!response) throw new Error("No response from onSubmit handler");
        const { error } = response;
        if (!error) return response;
        if (error.field) {
          setError(error.field, { type: "manual", message: error.prettyError || error.message });
          setFocus(error.field);
          const element = document.querySelector(`[name=${error.field}]`);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      } catch (e) {
        if (resetOnSubmit) {
          setTimeout(() => {
            reset();
          }, 1000);
        }
      }
    },
    [onSubmit, setError, setFocus, reset, resetOnSubmit]
  );

  const forceSubmit = useCallback(async () => {
    if (formRef.current) {
      let response;
      await handleSubmit(async (data) => {
        response = await onSubmitHandler(data);
        return response;
      }, onError)();

      return response;
    }
  }, [handleSubmit, onSubmitHandler, onError, formRef]);

  const validateFields = useCallback(
    async (fieldsToValidate = []) => {
      const errors = [];
      for (const field of fieldsToValidate) {
        if (!(await trigger(field))) errors.push(field);
      }

      if (!fieldsToValidate.length) {
        return await trigger();
      }

      return errors.length === 0;
    },
    [trigger]
  );

  useImperativeHandle(ref, () => ({
    reset,
    submit: forceSubmit,
    errors,
    setError: (...props) => {
      scrollToElement(props[0]);
      setError(...props);
    },
    setFocus,
    validateFields,
  }));

  return (
    <FormContextProvider
      register={register}
      getValues={getValues}
      setFormValue={setValue}
      errors={errors}
      submit={forceSubmit}
      clearErrors={clearErrors}
    >
      <form
        className={clsx(className)}
        ref={formRef}
        {...otherProps}
        onSubmit={handleSubmit(onSubmitHandler, onError)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            return e.preventDefault();
          }
        }}
      >
        {children}
      </form>
    </FormContextProvider>
  );
});
