import { useFetch, truncate, useUI, useStyling } from "src/shiftly-ui";

import styles from "./Conversation.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useMemo } from "react";

import { faArrowTurnLeft, faCircle } from "@fortawesome/pro-solid-svg-icons";

import useMessagesCriteria from "../../hooks/useMessagesCriteria";

const Conversation = (props) => {
  const { location, _id, setActiveConversation = () => {}, user, profile } = props;

  const { businessMode } = useUI();

  const styling = useStyling(styles);

  const criteria = useMessagesCriteria(props);

  const { data: messages } = useFetch({
    request: {
      entity: "Message",
      method: "getMessagesWithAccess",
      data: {
        conversation_id: _id,
        ...criteria,
      },
      id: "Message.GetMessagesWithAccess",
    },
    de: { conversation_id: _id, criteria },
    options: {
      enabled: Boolean(_id),
      staleTime: 0,
    },
  });

  const hasUnread = useMemo(() => {
    if (businessMode) return messages.some((message) => message?.unread && message?.for_id === location?._id);

    return messages.some((message) => message?.unread && message?.for_id === user?._id);
  }, [messages, user, businessMode, location]);

  const lastReceiver = useMemo(() => messages[messages.length - 1]?.for_id, [messages]);

  const showReturnArrow = useMemo(() => {
    if (businessMode) return lastReceiver !== location?._id && messages.length > 0;

    return lastReceiver !== user?._id && messages.length > 0;
  }, [businessMode, lastReceiver, location, user, messages]);

  const lastMessage = messages[messages.length - 1];

  return (
    <div
      className={styling("container", hasUnread && "hasUnread")}
      onClick={() => setActiveConversation({ ...props, profile })}
    >
      <div className={styling("icon")}>
        <img src={businessMode ? profile?.profile_picture : location?.logo} alt="location" />
      </div>
      <div className={styling("details")}>
        <h5>{businessMode ? `${user?.first_name} ${user?.last_name}` : location?.name}</h5>
        <p className={styling("preview")}>
          {showReturnArrow && <FontAwesomeIcon icon={faArrowTurnLeft} />}
          {lastMessage && truncate(lastMessage?.type === "text" ? lastMessage?.content : "Attachment", 35)}
        </p>
      </div>
      {hasUnread && <FontAwesomeIcon icon={faCircle} className={styling("chevron")} />}
    </div>
  );
};

export default Conversation;
