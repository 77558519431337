import { Button, Swiper } from "src/shiftly-ui";

import styles from "./WelcomeScreen.module.css";

import clsx from "clsx";

import Slide1 from "./assets/welcome_slide_1.png";

import Slide3 from "./assets/welcome_slide_3.png";

import { useNavigate } from "react-router-dom";

const slides = [
  {
    heading1: "Welcome to ",
    span: "Shiftly",
    text: "Secure shifts anwhere, at anytime, in minutes.",
    img: Slide1,
  },
  {
    span: "Communication ",
    heading2: "made easy",
    text: "Tired of waiting for employers to respond? Secure jobs with speedy response times or with no communication at all.",
    img: Slide1,
  },
  {
    heading1: "More ",
    span: "time, ",
    heading2: "more fun",
    text: "Remove endless scrolling & paperwork piles, we find shifts based on your terms.",
    img: Slide3,
  },
];

const WelcomeScreen = () => {
  const navigate = useNavigate();

  return (
    <div className={clsx(styles["container"])}>
      <div className={styles["swiper-container"]}>
        <Swiper
          slides={slides.map((slide, index) => (
            <Slide {...slide} index={index} />
          ))}
        />
      </div>
      <div className={styles["button-container"]}>
        <Button
          theme={"secondary"}
          className={styles["button"]}
          onClick={() => {
            navigate("/portal?page=signup");
          }}
        >
          {"Get Started"}
        </Button>
        <p className={styles["nav-text"]}>
          Already have an account? <span onClick={() => navigate("/portal?page=login")}>Login</span>
        </p>
      </div>
    </div>
  );
};

const Slide = ({ img, heading1 = "", span, text, heading2 = "" }) => {
  return (
    <div className={styles["slide"]}>
      <div className={clsx(styles["slide-img"])}>
        <img src={img} alt="slide-img" />
      </div>
      <div className={styles["slide-text"]}>
        <h2>
          {heading1}
          <span>{span}</span>
          {heading2}
        </h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default WelcomeScreen;
