import styles from "./InputWrapper.module.css";
import { Link } from "../buttons/Link";
import { useStyling } from "../../hooks/useStyling.js";
import { useRef, forwardRef } from "react";
import { useInlineStyles } from "../../hooks/useInlineStyles.js";
import clsx from "clsx";

export const withInputWrapper = (WrappedComponent) => {
  const WithRefWrapper = forwardRef(
    ({ disabled, name, required, label, link, className, error, specialButton, ...props }, ref) => {
      const styling = useStyling(styles);
      const style = useInlineStyles({ ...props });
      const inputRef = useRef();

      const combinedRef = ref || inputRef;

      return (
        <div
          className={clsx(styling("wrapper"), className)}
          style={style}
          onClick={(e) => {
            combinedRef?.current?.onClick?.(e);
            combinedRef?.current?.focus?.();
          }}
        >
          <div className={styling("header")}>
            {label && (
              <label className={styling("label")}>
                {label}: {required && <span>*</span>}
              </label>
            )}
            {link && (
              <Link to={link.destination} onClick={link.onClick} className={styles["link"]}>
                {link.label}
              </Link>
            )}
          </div>

          <div
            className={styling("container", disabled && "disabled")}
            style={{
              borderRadius: specialButton ? "0 7px 7px 0" : "7px",
              backgroundColor: specialButton ? "white" : "var(--input-background)",
            }}
          >
            <WrappedComponent
              ref={combinedRef}
              name={name}
              required={required}
              disabled={disabled}
              specialButton={specialButton}
              {...props}
            />
          </div>

          {error && (
            <div className={styling("error-container")}>
              <p className={styling("message")}>{error}</p>
            </div>
          )}
        </div>
      );
    }
  );

  return WithRefWrapper;
};
