import { roundNumber, truncate, useFetch, useAuth, useStyling } from "src/shiftly-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ShiftStack.module.css";
import { faInfo, faStar } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";

const ShiftCard = ({ shift = {}, setShowDetails = () => {}, mode = "stack" }) => {
  const { position, hourlyRate = 0, prettyDate, shiftPeriod, address = {}, location, modifier = 0 } = shift;

  const { user } = useAuth();

  const styling = useStyling(styles);

  const { post: deleteSavedShift, updateCache } = useFetch({
    options: {
      onMutate: ({ criteria: { shift } }) => {
        updateCache("Shift.GetSavedShiftsFromShiftIDs", (oldData = []) => oldData.filter((item) => item._id !== shift));
      },
    },
  });

  return (
    <div
      className={styling("shift")}
      onClick={() => {
        setShowDetails(shift);
      }}
    >
      <div className={styling("shift-swiper-container")}>
        <div className={styling("swiper-slide")}>
          <img src={location?.images?.[0]} alt="Business Logo" />
        </div>
        <div className={styling("business-logo")}>
          <img src={location?.logo} alt="Business Logo" />
        </div>
        <div
          className={styling("information-icon")}
          onClick={(e) => {
            e.stopPropagation();

            if (mode === "saved") {
              deleteSavedShift({
                entity: "ActionedShift",
                method: "delete",
                criteria: {
                  action: "saved",
                  shift: shift._id,
                  user: user?._id,
                },
              });
            } else {
              setShowDetails(shift);
            }
          }}
        >
          <FontAwesomeIcon icon={mode === "saved" ? faHeart : faInfo} />
        </div>
        <div className={styling("hourly-rate")}>
          <p>${roundNumber(hourlyRate + modifier, 2)}</p>
        </div>
      </div>
      <div className={styling("shift-details")}>
        <div className={styling("shift-details-main")}>
          <div className={styling("shift-details-info")}>
            <h3>{truncate(position?.name, 15)}</h3>
            <p className={styling("address")}>{truncate(address?.street, 18)}</p>
            <p className={styling("address")}>{address?.city}</p>
          </div>
          <div className={styling("shift-details-date")}>
            <h3>{prettyDate}</h3>
            <p>{shiftPeriod}</p>
          </div>
        </div>
      </div>
      <div className={styling("shift-footer")}>
        <div className={styling("shift-qualifications")}>
          {position?.qualifications?.slice(0, 3).map((qualification) => (
            <p key={qualification.id}>{truncate(qualification.description, 10)}</p>
          ))}
        </div>
        <div className={styling("shift-rating")}>
          <FontAwesomeIcon icon={faStar} />
          <p>{location?.rating || 5}</p>
        </div>
      </div>
    </div>
  );
};

export default ShiftCard;
