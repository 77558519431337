import { formatPayPeriod, useStyling, useFetch, useAuth, useToast } from "src/shiftly-ui";

import styles from "./PayHistoryCard.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import moment from "moment";

import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";

import { useCallback } from "react";

const getPaidDate = (date) => {
  return moment(date).format("Do MMMM YY");
};

const PayHistoryCard = ({ payrun = {} }) => {
  const styling = useStyling(styles);

  const { user } = useAuth();

  const toast = useToast();

  const { start_date, end_date, pay_date } = payrun;

  const { post: sendPaySlip } = useFetch({
    options: {
      onMutate: () => {
        toast.success("Pay slip sent, please check your emails.");
      },
    },
  });

  const handleSend = useCallback(() => {
    sendPaySlip({
      entity: "PaySlip",
      method: "sendPayslips",
      criteria: {
        payrun: payrun._id,
        user: user._id,
        $or: [{ sent: false }, { sent: true }],
      },
    });
  }, [sendPaySlip, payrun, user]);

  return (
    <div className={styling("container")}>
      <div className={styling("details")}>
        <h5>{formatPayPeriod(start_date, end_date)}</h5>
        <div className={styling("status")}>
          <FontAwesomeIcon icon={faCheck} />
          <p>Paid: {getPaidDate(pay_date)}</p>
        </div>
      </div>
      <div className={styling("buttons")}>
        <div className={styling("button")}>
          <FontAwesomeIcon icon={faPaperPlane} onClick={handleSend} />
        </div>
      </div>
    </div>
  );
};

export default PayHistoryCard;
