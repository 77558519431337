const generalMessages = {
  // Server
  internal_server_error: {
    code: 2000,
    message: "Internal server error",
    prettyError: "Something went wrong. We'll have it sorted in no time. Please try again later!",
  },
  // Forms
  form_validation_error: {
    code: 2001,
    message: "Form validation error",
    prettyError: "Please check that all mandatory fields are filled out correctly.",
  },
  required_field: {
    message: (field) => `${field} is required`,
    prettyError: (field) => `Don't forget to enter your ${field}.`,
  },
  invalid_field: {
    code: 2002,
    message: (field) => `Invalid value for ${field}`,
    prettyError: (field) => `Hmm, that ${field} doesn't look right. Let's try again.`,
  },

  not_found: {
    code: 2003,
    message: (field) => `${field} not found`,
    prettyError: `An internal server error occurred. Please try again later.`,
  },

  // Network
  catch_all_error: {
    code: 3001,
    message: "An unexpected error occurred",
    prettyError: "Something went wrong. We'll have it sorted in no time. Please try again later!",
  },

  //Not Supported
  not_supported: {
    code: 3002,
    message: "Not supported",
    prettyError: "This feature isn't supported yet. Please check back later.",
  },

  // Entity Validation
  invalid_object_id: {
    code: 2003,
    message: (field) => `Invalid object ID for ${field}`,
    prettyError: (field) => `The ${field} ID doesn't seem valid. Let's double-check it.`,
  },
  not_in_enum_list: {
    code: 2004,
    message: (field) => `${field} is not in the allowed list`,
    prettyError: (field) => `The value for ${field} isn't valid. Please select a valid option.`,
  },
  must_be_positive: {
    code: 2005,
    message: (field) => `${field} must be a positive number`,
    prettyError: (field) => `The value for ${field} needs to be positive. Let's correct that.`,
  },
};

const authenticationMessages = {
  // Login
  no_email_or_password_provided: {
    code: 4001,
    message: "Email or password not provided",
    prettyError: "Oops! Please enter both your email and password.",
  },
  no_associated_account_with_email: {
    code: 4002,
    message: "No account associated with this email",
    prettyError: "We couldn't find an account with that email. Want to sign up instead?",
  },
  incorrect_password: {
    code: 4003,
    message: "Incorrect password",
    prettyError: "That password didn't work. Let's try again!",
  },
  incomplete_business_setup: {
    code: 4004,
    message: "Incomplete business setup",
    prettyError: "You need to complete your business setup on a desktop before you can log in here.",
  },
  email_with_setup_link_sent: {
    code: 4005,
    message: "Email with setup link sent",
    prettyError: "We've sent you an email with a link to complete your business setup. Please check your inbox.",
  },
  cant_deactivate_account_with_shifts: {
    code: 4006,
    message: "Cannot deactivate account with shifts",
    prettyError:
      "You can't deactivate your account while you have upcoming shifts. Please contact support for assistance.",
  },

  // Signup
  signup_with_existing_email: {
    code: 1001,
    message: "Email already exists",
    prettyError: "You're already with us! Please login or reset your password if needed.",
  },
  stronger_password_required: {
    code: 1002,
    message: "Password strength is insufficient",
    prettyError: "Let's make your password a bit stronger for better security.",
  },
  passwords_do_not_match: {
    code: 1003,
    message: "Passwords do not match",
    prettyError: "The passwords don't match. Double-check and try again.",
  },

  // Reset Password
  failed_to_send_reset_email: {
    code: 1004,
    message: "Failed to send reset email",
    prettyError: "We couldn't send the reset email. Let's give it another shot.",
  },
  reset_password_token_expired: {
    code: 1005,
    message: "Reset password token expired",
    prettyError: "The reset link has expired. No worries, just request a new one!",
  },
};

const businessSetupMessages = {
  // ABN
  abn_invalid: {
    code: 5001,
    message: "Invalid ABN",
    prettyError: "Hmm, the ABN you entered doesn't seem right. Could you check it and try again?",
  },
  abn_not_active: {
    code: 5002,
    message: "Inactive ABN",
    prettyError: "It looks like this ABN isn't active. Please use an active one.",
  },
  abn_in_use: {
    code: 5003,
    message: "ABN already in use",
    prettyError: "This ABN is already linked to another account. Maybe try a different one?",
  },

  // Setup
  business_details_incomplete: {
    code: 5004,
    message: "Incomplete business details",
    prettyError: "You're almost there! Just finish filling out your business details to continue.",
  },
  selected_indsutry_incomplete: {
    code: 5005,
    message: "Industry selection incomplete",
    prettyError: "Let's choose your industry to move forward.",
  },
  address_incomplete: {
    code: 5006,
    message: "Address incomplete",
    prettyError: "Don't forget to complete your address details.",
  },
  location_incomplete: {
    code: 5007,
    message: "Location incomplete",
    prettyError: "Please ensure your business location details are complete.",
  },
};

const locationMessages = {
  // Location
  location_not_found: {
    code: 6001,
    message: "Location not found",
    prettyError: "We couldn't find that location. Please check and try again.",
  },
  location_already_exists: {
    code: 6002,
    message: "Location already exists",
    prettyError: "This location is already in the system. Please choose a different name or location.",
  },
};

const paymentMessages = {
  // General
  cannot_delete_payment: {
    code: 7001,
    message: "Cannot delete payment",
    prettyError: "Sorry, this payment can't be deleted right now. Please try again later.",
  },

  // Shift
  shift_must_be_confirmed_or_published: {
    code: 7002,
    message: "Shift must be confirmed or published",
    prettyError: "The shift needs to be confirmed or published before you can proceed.",
  },

  // Payment
  payment_failed: {
    code: 7003,
    message: "Payment failed",
    prettyError: "Something went wrong with the payment. Let's try that again.",
  },
  payment_not_found: {
    code: 7004,
    message: "Payment not found",
    prettyError: "We couldn't find that payment. Please check and try again.",
  },
  failed_too_process_refund: {
    code: 7005,
    message: "Failed to process refund",
    prettyError: "The refund couldn't be processed. Please contact support if the issue persists.",
  },

  // Payment Method
  payment_method_not_found: {
    code: 7006,
    message: "Payment method not found",
    prettyError: "We couldn't find that payment method. Please check and try again.",
  },
  payment_method_already_exists: {
    code: 7007,
    message: "Payment method already exists",
    prettyError: "This payment method is already in use. Please select another one.",
  },
  failed_to_set_as_default: {
    code: 7008,
    message: "Failed to set as default",
    prettyError: "Couldn't set this payment method as default. Please try again.",
  },
  cannot_delete_default_payment_method: {
    code: 7009,
    message: "Cannot delete default payment method",
    prettyError: "You can't delete the default payment method. Please set another method as default first.",
  },
};

const positionMessages = {
  position_already_exists: {
    code: 8001,
    message: "Position already exists",
    prettyError: "This position already exists. Please choose a different name or title.",
  },
  position_not_found: {
    code: 8002,
    message: "Position not found",
    prettyError: "We couldn't find that position. Please check and try again.",
  },
  no_position_group_found: {
    code: 8003,
    message: "No position group found",
    prettyError: "No group associated with this position was found. Please select a valid group.",
  },
};

const shiftMessages = {
  // Changing Shifts
  cannot_edit_shift: {
    code: 9001,
    message: "Cannot edit shift",
    prettyError: "Sorry, you can't edit this shift right now. Please try again later.",
  },
  shift_has_not_been_confirmed: {
    code: 9002,
    message: "Shift has not been confirmed",
    prettyError: "This shift hasn't been confirmed yet. Please confirm it before making changes.",
  },
};

const onboardingMessages = {
  expired_onboarding_link: {
    code: 10001,
    message: "Expired onboarding link",
  },

  item_incomplete: {
    code: 10002,
    message: (field) => `${field} is incomplete`,
    prettyError: (field) =>
      `Please complete the ${field} section of your profile before continuing with the onboarding process.`,
  },

  xero_error: {
    code: 10003,
    message: "Xero error",
    prettyError: (field) => `${field}`,
  },

  onboarding_not_found: {
    code: 10004,
    message: "Onboarding not found",
    prettyError: "We couldn't find that onboarding session. Please check and try again.",
  },

  internal_staff_not_found: {
    code: 10004,
    message: "Internal Staff member not found",
    prettyError: "An internal server error occurred. Please try again later.",
  },
};

const superFundMessages = {
  already_exists: {
    code: 11001,
    message: "Super fund already exists",
    prettyError: "This super fund already exists.",
    field: "usi",
  },

  not_found: {
    code: 11002,
    message: "Super fund not found",
    prettyError: "We couldn't find that super fund. Please check and try again.",
    field: "usi",
  },
};

const taxDeclarationMessages = {
  invalid_income_type_employee: {
    code: 12001,
    message: "Invalid Income Type",
    prettyError:
      "Employees must have an income type of either salary and wages, closely held payees or working holiday maker.",
  },

  invalid_income_type_contractor: {
    code: 12002,
    message: "Invalid Income Type",
    prettyError: "Contractors must have an income type of either labour hire or non employee.",
  },

  invalid_employment_basis_enum: {
    code: 12003,
    message: "Invalid Employment Basis",
    prettyError: "Employment basis must be either full-time, part-time, casual, labour hire or non-employee.",
  },

  invalid_employment_basis_employee: {
    code: 12004,
    message: "Invalid Employment Basis",
    prettyError: "Employees must have an employment basis of either full-time, part-time or casual.",
  },

  invalid_employment_basis_non_employee: {
    code: 12005,
    message: "Invalid Employment Basis",
    prettyError: "Contractors must have an employment basis of non-employee.",
  },

  invalid_employment_basis_labour_hire: {
    code: 12006,
    message: "Invalid Employment Basis",
    prettyError: "Labour hire contractors must have an employment basis of labour hire.",
  },

  invalid_country_of_residence_australian_resident: {
    code: 12007,
    message: "Invalid Country of Residence",
    prettyError: "Australian residents must not have a country of residence.",
  },

  invalid_country_of_residence_foreign_resident: {
    code: 12008,
    message: "Invalid Country of Residence",
    prettyError: "Foreign residents must specify their country of residence.",
  },

  invalid_residency_status_enum: {
    code: 12009,
    message: "Invalid Residency Status",
    prettyError: "Residency status must be either Australian resident, foreign resident or working holiday maker.",
  },

  invalid_residency_status_non_employee: {
    code: 12010,
    message: "Invalid Residency Status",
    prettyError: "Non-employees must have a residency status of Australian resident.",
  },

  invalid_residency_status_working_holiday_maker: {
    code: 12011,
    message: "Invalid Residency Status",
    prettyError: "Working holiday makers must have a residency status of foreign resident.",
  },

  tfn_required_if_not_exempt: {
    code: 12012,
    message: "TFN required if not exempt",
    prettyError: "Tax File Number is required if no TFN Exemption has been provided.",
  },
  invalid_tfn: {
    code: 12013,
    message: "Invalid TFN",
    prettyError: "Please enter a valid tax file number.",
  },
  invalid_tfn_exemption_enum: {
    code: 12014,
    message: "Invalid TFN Exemption",
    prettyError: "Please select a valid TFN exemption.",
  },

  whm_pensioner_under18: {
    code: 12015,
    message: "Pensioners and under 18s cannot be working holiday makers",
    prettyError: "Pensioners and under 18s cannot be working holiday makers.",
  },
  tfn_and_exemption_cannot_both_be_provided: {
    code: 12016,
    message: "TFN and TFN Exemption provided",
    prettyError: "Tax File Number is not required if a TFN Exemption has been provided.",
  },

  invalid_tax_scale_type_enum: {
    code: 12017,
    message: "Invalid Tax Scale Type",
    prettyError: "Please select a valid tax scale type.",
  },

  invalid_tax_scale_type_non_employee: {
    code: 12018,
    message: "Invalid Tax Scale Type",
    prettyError: "Non-employees do not follow a tax scale type.",
  },

  invalid_tax_scale_type_foreign_resident_required: {
    code: 12019,
    message: "Invalid Tax Scale Type",
    prettyError: "Foreign residents must follow the Foreign, Working Holiday Maker or Horticulturist tax scale type.",
  },

  invalid_tax_scale_type_foreign_resident_exclusive: {
    code: 12020,
    message: "Invalid Tax Scale Type",
    prettyError: "Foreign tax scale type is only for foreign residents.",
  },

  invalid_tax_scale_type_whm: {
    code: 12021,
    message: "Invalid Tax Scale Type",
    prettyError: "Working Holiday Maker tax scale type is only for foreign residents.",
  },

  invalid_tax_scale_type_whm_required: {
    code: 12022,
    message: "Invalid Tax Scale Type",
    prettyError: "Working Holiday Maker tax scale type is required for working holiday makers",
  },

  tax_scale_type_tfn_exemption_not_quoted: {
    code: 12023,
    message: "Tax Scale Type cannot be provided if TFN Exemption is not quoted",
    prettyError: "Tax Scale Type cannot be provided if TFN Exemption is not quoted.",
  },

  invalid_work_condition_enum: {
    code: 12025,
    message: "Invalid Work Condition",
    prettyError: "Please select a valid work condition.",
  },

  actors_entertainers_require_work_condition: {
    code: 12026,
    message: "Actors and entertainers require a work condition",
    prettyError: "Actors and entertainers require a work condition.",
  },

  invalid_work_condition_not_required: {
    code: 12027,
    message: "Invalid Work Condition",
    prettyError: "Only actors and entertainers can have a work condition.",
  },

  invalid_senior_marital_status_enum: {
    code: 12028,
    message: "Invalid Senior Marital Status",
    prettyError: "Please select a valid senior marital status.",
  },

  invalid_senior_marital_status_required: {
    code: 12029,
    message: "Invalid Senior Marital Status",
    prettyError: "Senior tax offset is required to be set for seniors.",
  },

  invalid_senior_marital_status_not_required: {
    code: 12030,
    message: "Invalid Senior Marital Status",
    prettyError: "Only seniors can have a senior marital status.",
  },

  invalid_help_debt_non_employee: {
    code: 12031,
    message: "Invalid Help Debt",
    prettyError: "Non-employees cannot have a loan or student debt.",
  },

  invalid_help_debt_foreign_resident: {
    code: 12032,
    message: "Invalid Help Debt",
    prettyError: "Foreign residents cannot have a loan or student debt.",
  },

  invalid_threshold_non_employee: {
    code: 12033,
    message: "Invalid Threshold",
    prettyError: "Non-employees cannot have a tax-free threshold.",
  },

  invalid_threshold_foreign_resident: {
    code: 12034,
    message: "Invalid Threshold",
    prettyError: "Foreign residents cannot have a tax-free threshold.",
  },

  invalid_threshold_horticultralist: {
    code: 12035,
    message: "Invalid Threshold",
    prettyError: "Horticulturists and shearers must claim the tax-free threshold.",
  },

  invalid_threshold_actor_entertainer: {
    code: 12036,
    message: "Invalid Threshold",
    prettyError: "Actors and entertainers cannot claim the tax-free threshold.",
  },

  invalid_leave_loading_non_employee: {
    code: 12037,
    message: "Invalid Leave Loading",
    prettyError: "Non-employees cannot have leave loading.",
  },

  invalid_leave_loading_casual: {
    code: 12038,
    message: "Invalid Leave Loading",
    prettyError: "Casual employees cannot have leave loading.",
  },
};

module.exports = {
  authenticationMessages,
  generalMessages,
  businessSetupMessages,
  locationMessages,
  paymentMessages,
  positionMessages,
  shiftMessages,
  onboardingMessages,
  superFundMessages,
  taxDeclarationMessages,
};
