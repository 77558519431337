import { useStyling, formatPayPeriod } from "src/shiftly-ui";

import styles from "./PayRunCard.module.css";

import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

import PayRunBreakdown from "./PayRunBreakdown";

import { useState } from "react";

const getPayDate = (date) => {
  return moment(date).add(3, "days").format("Do MMMM");
};

const getProcessingDate = (date) => {
  return moment(date).add(1, "days").format("Do MMMM");
};

const PayRunCard = ({ status = "", payrun = {}, timesheets = [] }) => {
  const styling = useStyling(styles);

  const [showDetail, setShowDetail] = useState(false);

  const { start_date, end_date } = payrun;

  if (!start_date || !end_date) return null;

  return (
    <>
      {status === "Active" && (
        <PayRunBreakdown timesheets={timesheets} payrun={payrun} show={showDetail} setShow={setShowDetail} />
      )}
      <div
        className={styling("container")}
        onClick={() => {
          if (status !== "Active") return;

          setShowDetail(true);
        }}
      >
        {status === "Active" && <FontAwesomeIcon icon={faChevronRight} className={styling("chevron")} />}
        <div className={styling("status", status)}>
          <p>{status}</p>
        </div>
        <div className={styling("title")}>
          <h4>Payrun: {formatPayPeriod(start_date, end_date)}</h4>
        </div>
        {status === "Active" && <div className={styling("completed-shifts")}>{timesheets.length} Shifts Completed</div>}
        <div className={styling("scheduled")}>
          {status === "Active" ? (
            <p>Scheduled processing date: {getProcessingDate(end_date)}</p>
          ) : (
            <p>Scheduled payment date: {getPayDate(end_date)}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PayRunCard;
