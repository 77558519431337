import { Button, FormV2, useAuth, Input } from "src/shiftly-ui";

import styles from "./Portal.module.css";

import { useCallback } from "react";

import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";

const Login = () => {
  const { login, loginIsLoading: isLoading } = useAuth();

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data) => {
      return await login(data, "shifter");
    },
    [login]
  );

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={styles["content-container"]}
    >
      <h2 className={styles["heading"]}>
        Welcome back to <span>Shiftly</span>
      </h2>
      <p className={styles["subtext"]}>
        Log in and access all the new shifts that were posted while you were out doing your thing!
      </p>
      <FormV2 onSubmit={onSubmit} className={styles["form-container"]}>
        <div className={styles["content"]}>
          <Input name={"email"} />
          <Input name={"password"} submitOnEnter validate={false} />
          <p className={styles["login-forgot-password"]} onClick={() => navigate("/portal?page=reset")}>
            Forgotten Password? <span>Reset now</span>
          </p>
        </div>
        <div className={styles["button-container"]}>
          <Button theme={"secondary"} size={"fw"} type="submit" loading={isLoading}>
            Login
          </Button>
          <Button
            theme={"secondary-outline"}
            size={"fw"}
            mt={"20px"}
            onClick={() => window.open(process.env.REACT_APP_HOST_APP_URL)}
            disabled={isLoading}
          >
            I'm a business owner
          </Button>
          <p className={styles["nav-text"]}>
            Are you new here? <span onClick={() => navigate("/portal?page=signup")}>Create an account</span>
          </p>
        </div>
      </FormV2>
    </motion.div>
  );
};

export default Login;
