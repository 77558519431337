import { useAuth, FunctionalWrapper, Authentication, ErrorBoundary } from "src/shiftly-ui";
import { Route, Routes, useLocation } from "react-router-dom";
import Search from "src/pages/search";
import Saved from "src/pages/saved/Saved";
import Shifts from "src/pages/shifts/ShiftsHome";
import Inbox from "src/pages/inbox";
import ShifterWrapper from "./components/layout/ShifterWrapper";
import Main from "./components/layout/Main";
import CompletedShift from "./pages/shifts/CompletedShift";
import PaymentsHome from "./pages/payments/PaymentsHome";
import PaymentHistory from "./pages/payments/PaymentHistory";
import ShiftHistory from "./pages/shifts/ShiftHistory";
import ProfileHome from "./pages/profile/home/ProfileHome";
import DigitalCV from "./pages/profile/digital_cv/DigitalCV";
import EmploymentDetails from "./pages/profile/employment_details/EmploymentDetails";
import Security from "./pages/profile/security/Security";
import TrashedShifts from "./pages/shifts/TrashedShifts";
import Login from "./pages/authentication/Login";
import SignUp from "./pages/authentication/SignUp";
import ResetPassword from "./pages/authentication/ResetPassword";
import WelcomeScreen from "./pages/authentication/WelcomeScreen/WelcomeScreen";

const App = () => {
  const location = useLocation();

  const { user } = useAuth();

  return (
    <ErrorBoundary>
      <FunctionalWrapper init_id={user?._id}>
        <Main>
          <Routes location={location} key={location.pathname}>
            <Route
              path="/portal"
              exact
              element={
                <Authentication
                  login={<Login />}
                  signup={<SignUp />}
                  reset={<ResetPassword />}
                  welcome={<WelcomeScreen />}
                />
              }
            />
          </Routes>
          {user && (
            <ShifterWrapper>
              <Routes location={location} key={location.pathname}>
                <Route path="/" exact element={<Search />} />
                <Route path="/saved" exact element={<Saved />} />
                <Route path="/shifts" exact element={<Shifts />} />
                <Route path="/shift-history" exact element={<ShiftHistory />} />
                <Route path="/trashed-shifts" exact element={<TrashedShifts />} />
                <Route path="/completed-shift" exact element={<CompletedShift />} />
                <Route path="/payments" exact element={<PaymentsHome />} />
                <Route path="/payment-history" exact element={<PaymentHistory />} />
                <Route path="/inbox" exact element={<Inbox />} />
                <Route path="/profile" exact element={<ProfileHome />} />
                <Route path="/digital-cv" exact element={<DigitalCV />} />
                <Route path="/security" exact element={<Security />} />
                <Route path="/employment-details" exact element={<EmploymentDetails />} />
              </Routes>
            </ShifterWrapper>
          )}
        </Main>
      </FunctionalWrapper>
    </ErrorBoundary>
  );
};

export default App;
