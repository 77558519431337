import { withInputWrapper } from "../HOC/withInputWrapper";
import { useStyling } from "../../hooks/useStyling";
import styles from "./Input.module.css";
import { useState, useEffect, forwardRef } from "react";
import { faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withInputBase } from "../HOC/withInputBase";

const formatDateForInput = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
};

export const Input = withInputBase(
  withInputWrapper(
    forwardRef(
      (
        {
          icon,
          formProps,
          type = "text",
          value,
          setValue,
          submitOnEnter,
          onEnterPress,
          specialButton,
          validate,
          defaults: { validate: defaultValidate, required: defaultRequired, ...defaults },
          required,
          ...props
        },
        ref
      ) => {
        const styling = useStyling(styles);

        const [inputType, setInputType] = useState(type || defaults.type);
        const [inputIcon, setInputIcon] = useState(icon);

        useEffect(() => {
          if (icon) {
            setInputIcon(icon);
          }

          if (type === "password") {
            setInputIcon({
              icon: faEye,
              side: "right",
              onClick: () =>
                setInputType(() => {
                  return inputType === "password" ? "text" : "password";
                }),
            });
          }
        }, [inputType, type, icon]);

        useEffect(() => {
          if (type === "date" && typeof value === "string" && value?.includes("T")) setValue(formatDateForInput(value));
        }, [type, value, setValue]);

        return (
          <div className={styling("container", inputIcon && "hasIcon-" + inputIcon.side)}>
            <input
              {...defaults}
              {...props}
              {...formProps}
              type={inputType}
              ref={(e) => {
                formProps?.ref?.(e);
                ref.current = e;
              }}
            />
            {inputIcon && (
              <div className={styling("icon", inputIcon?.side, icon?.className)} onClick={inputIcon?.onClick}>
                <FontAwesomeIcon icon={inputIcon?.icon} />
              </div>
            )}
          </div>
        );
      }
    )
  )
);
