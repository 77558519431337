import { useStyling } from "src/shiftly-ui";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./SFAction.module.css";

const SFAction = ({ className, onClick, icon, spin, text, isTouched }) => {
  const styling = useStyling(styles);

  return (
    <div
      className={clsx(styling("refresh"), styling(className), isTouched && styling(text + "-touched"))}
      onClick={onClick}
    >
      <div className={styling("refresh-content-button")}>
        <FontAwesomeIcon icon={icon} spin={spin} />
      </div>
      <p className="small">{text}</p>
    </div>
  );
};

export default SFAction;
