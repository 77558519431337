import { useState, useEffect } from "react";
import clsx from "clsx";
import styles from "./LoadingSpinner.module.css";

export const LoadingSpinner = ({ colour = "orange", loader, minVisibleDuration = 1000 }) => {
  const [isMounted, setIsMounted] = useState(loader);
  const [lastMountedTime, setLastMountedTime] = useState(Date.now());

  useEffect(() => {
    let timeoutId;
    if (loader) {
      setIsMounted(true);
      setLastMountedTime(Date.now());
    } else {
      const timeSinceMounted = Date.now() - lastMountedTime;
      const remainingTime = Math.max(0, minVisibleDuration - timeSinceMounted);

      timeoutId = setTimeout(() => setIsMounted(false), remainingTime);
    }

    return () => clearTimeout(timeoutId);
  }, [loader, minVisibleDuration]);

  const mountedStyle = { animation: "inAnimation 500ms ease-in" };
  const unmountedStyle = {
    animation: "outAnimation 500ms ease-out",
    animationFillMode: "forwards",
  };

  return (
    <span
      style={isMounted ? mountedStyle : unmountedStyle}
      className={clsx(styles["loader"], colour && styles[colour])}
    ></span>
  );
};
