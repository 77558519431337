import { useStyling, useUI, useDelayUnmount, useFetch, useSearchParams } from "src/shiftly-ui";
import { useEffect, useState } from "react";
import { animated } from "@react-spring/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faTimes, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faRefresh } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import useCardSwipe from "../hooks/useCardSwipe";
import useSlideOverlaySwipe from "../hooks/useSlideOverlaySwipe";
import useShifts from "src/hooks/useShifts";
import ShiftCard from "./ShiftCard";
import ShiftDetails from "./shift_details/ShiftDetails";
import ShiftSkeleton from "./ShiftSkeleton";
import instructions from "./assets/instructions.svg";
import styles from "./ShiftStack.module.css";
import { faBookOpen } from "@fortawesome/pro-regular-svg-icons";
import SFAction from "./search_bar/shift_filter/components/SFAction";

const ShiftStack = () => {
  const styling = useStyling(styles);

  const [showInstructions, setShowInstructions] = useState(false);

  const [showDetails, setShowDetails] = useState(false);

  const {
    settings: { new_user },
  } = useUI();

  const { springs, bind, shifts, loveAnimation, trashAnimation } = useCardSwipe();

  const { hideOverlay, bindSwipe, swiperOverlay, showOverlay } = useSlideOverlaySwipe();

  const { loading, handleSearch, setFilter } = useShifts();

  const showLoader = useDelayUnmount(loading);

  const { post: fetchShift } = useFetch({
    options: {
      onSuccess: (data) => {
        setShowDetails(data[0] || false);
      },
    },
  });

  useEffect(() => {
    if (new_user && !loading && shifts.length > 0) {
      setTimeout(() => {
        setShowInstructions(true);

        showOverlay();
      }, 1000);
    }
  }, [new_user, showOverlay, loading, shifts]);

  useSearchParams(["shift"], ({ shift: shiftID }) => {
    if (shiftID) {
      fetchShift({
        entity: "Shift",
        method: "getWithCalculatedFields",
        criteria: { _id: shiftID },
      });
    }
  });

  return (
    <>
      <ShiftDetails showDetails={showDetails} setShowDetails={setShowDetails} />
      <div className={styling("container")}>
        <div className={styling("info-icon")} onClick={() => setShowInstructions(true)}>
          <FontAwesomeIcon icon={faBookOpen} />
        </div>
        <div className={clsx(styling("emoticon"), styling("emoticon-love"), loveAnimation && styling("animate"))}>
          <FontAwesomeIcon icon={faHeart} />
        </div>
        <div className={clsx(styling("emoticon"), styling("emoticon-trash"), trashAnimation && styling("animate"))}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
        <div
          className={styling("intro-overlay")}
          onClick={() => {
            setShowInstructions(false);

            hideOverlay({ friction: 50, tension: 800 });
          }}
        >
          <div className={clsx(styling("intro-background"), showInstructions && styling("showInstructions"))}></div>
          <div className={clsx(styling("instructions"), showInstructions && styling("showInstructions"))}>
            <img src={instructions} alt="instructions" />
          </div>
        </div>
        <animated.div {...bindSwipe()} style={swiperOverlay} className={styling("swipe-up-overlay")}>
          <div className={styling("swipe-up-content")}>
            <div className={styling("swipe-up-bar")}></div>
            <div className={styling("swipe-up-text")}>
              <h3>Scroll, Swipe or Tap</h3>
              <p className={"medium"}>
                Find, save and remove shifts from your search by completing the simple actions listed above.
              </p>
              <FontAwesomeIcon
                icon={faTimes}
                className={styling("close-icon")}
                onClick={() => hideOverlay({ friction: 50, tension: 800 })}
              />
            </div>
          </div>
        </animated.div>
        {showLoader ? (
          <ShiftSkeleton />
        ) : shifts?.length ? (
          <div className={styling("stack-container")}>
            {springs.flatMap(({ x, y, rot, scale, zIndex, boxShadow }, i) =>
              shifts[i] ? (
                <animated.div
                  className={styling("shift-container")}
                  key={i}
                  style={{ x, y, scale, rot, zIndex, boxShadow }}
                  {...bind(i)}
                >
                  <ShiftCard shift={shifts[i]} index={i} setShowDetails={setShowDetails} />
                </animated.div>
              ) : (
                []
              )
            )}
          </div>
        ) : (
          <div className={styling("no-shifts")}>
            <p>
              You've swiped on all our shifts!
              <br />
              Reset your filters to find some more!
            </p>
            <SFAction
              text={"Refresh"}
              icon={faRefresh}
              className={"refresh"}
              onClick={() => {
                setFilter({
                  what: {
                    descriptions: [],
                    ids: [],
                  },
                  where: {},
                  when: [],
                  wage: 0,
                });

                handleSearch({
                  what: {
                    descriptions: [],
                    ids: [],
                  },
                  where: {},
                  when: [],
                  wage: 0,
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ShiftStack;
